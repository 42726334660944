<template>
	<!-- usePagination开启分页 -->
	<!-- loading绑定引入页面的蒙层加载变量 -->
	<q-container usePagination :loading="loading">
		<!-- 使用具名插槽 #toolbar -->
		<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
		<template #toolbar>
			<a-row :gutter="10">
				<a-col :span="6" class="d-flex align-items-center">
					<span style="text-align: right;margin: 0;flex-shrink: 0;padding-right: 3px">开始日期：</span>
					<a-range-picker
						style="width: 100%"
						format="YYYY-MM-DD"
						v-model="initialValue"
						@change="changePeriod"
					/>
				</a-col>
				<a-col :span="4">
					<a-tree-select
						style="width: 100%"
						@change="handleCompanyChange"
						allowClear
						multiple
						tree-default-expand-all
						:placeholder="l('Company')"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
						:tree-data="companyTreeList"
						v-model="comPanyId"
					/>
				</a-col>
				<a-col :span="4">
					<a-tree-select
						style="width: 100%"
						multiple
						allowClear
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
						:tree-data="DepTreeData"
						:placeholder="l('Department')"
						tree-default-expand-all
						v-model="departmentId"
						@change="getData"
					>
					</a-tree-select>
				</a-col>
				<a-col :span="3">
					<a-select
						show-search
						:placeholder="l('SignCardType')"
						option-filter-prop="children"
						style="width: 100%"
						allowClear
						@change="getData"
						v-model="SignCardTypeCode"
					>
						<a-select-option v-for="item in SignCardTypeList" :key="item.itemDetailCode">
							{{ item.itemDetailName }}
						</a-select-option>
					</a-select>
				</a-col>
				<a-col :span="3">
					<a-select
						show-search
						:placeholder="l('SignCardReason')"
						option-filter-prop="children"
						style="width: 100%"
						allowClear
						@change="getData"
						v-model="SignCardReasonCode"
					>
						<a-select-option v-for="item in SignCardReasonList" :key="item.itemDetailCode">
							{{ item.itemDetailName }}
						</a-select-option>
					</a-select>
				</a-col>
				<a-col :span="4" style="text-align: right">
					<a-input-search
						v-model.trim="filterText"
						placeholder="请输入搜索内容..."
						style="line-height: 1;width: 200px;"
						enter-button
						@search="search"
					/>
				</a-col>
			</a-row>
			<a-row style="margin: 10px 0" class="d-flex">
				<a-col>
					<a-button type="primary" @click="exportFormClick" v-if="isGranted('replenish_card_excel')">
						<a-icon type="download" />
						<span>导出报表</span>
					</a-button>
				</a-col>
			</a-row>
		</template>
		<!-- 使用具名插槽slot="table" -->
		<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
		<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

		<!-- **************行选择*************** -->
		<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
		<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
		<template slot="table" slot-scope="scopeParam">
			<a-table
				ref="table"
				size="small"
				:pagination="false"
				:columns="columns"
				:rowKey="(tableDatas) => tableDatas.id"
				:scroll="{ y: scopeParam.scroll.y }"
				:dataSource="tableData"
				:customRow="customRow"
				:rowClassName="rowClassName"
			>
				<span slot="attDate" slot-scope="text">{{ text.format('YYYY-MM-DD') }}</span>
				<span slot="checkTime" slot-scope="text">{{ text.format('YYYY-MM-DD HH:mm') }}</span>
			</a-table>
		</template>
		<!-- 使用具名插槽 #page -->
		<template #page>
			<!-- 分页组件只需复制以下代码 -->
			<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
			<a-pagination
				v-model="pageNumber"
				:total="totalItems"
				showSizeChanger
				size="small"
				:defaultPageSize="request.maxResultCount"
				:pageSizeOptions="pageSizeOptions"
				:showTotal="() => showTotal"
				@change="onPageChange"
				@showSizeChange="showPageSizeChange"
			/>
		</template>
	</q-container>
</template>

<script>
import {
	CompanyServiceProxy,
	DepartmentServiceProxy,
	SignCardRecordServiceProxy,
} from '../../../shared/service-proxies';
import { Dic, fileDownloadService } from '../../../shared/utils';
import { AppComponentBase } from '../../../shared/component-base';
import moment from 'moment';
import { abpService } from '../../../shared/abp';
import DepTreeUntils from '../../organization/department/dep-tree-untils/dep-tree-untils';

export default {
	name: 'replenish-card',
	mixins: [AppComponentBase],
	data() {
		return {
			initialValue: undefined,
			companyTreeList: [], //公司
			comPanyId: undefined, //公司id
			departmentId: undefined,
			//部门树
			DepTreeData: [],
			DepTree: [],

			SignCardTypeList: [],
			SignCardTypeCode: undefined,
			SignCardReasonList: [],
			SignCardReasonCode: undefined,
			startTime: undefined, //开始时间
			endTime: undefined, //结束时间

			// 用户表格
			columns: [
				{
					title: '工号',
					dataIndex: 'jobNumber',
					sorter: true,
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'jobNumber' },
				},
				{
					title: '姓名',
					dataIndex: 'realName',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'realName' },
				},
				{
					title: '公司',
					dataIndex: 'companyName',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'companyName' },
				},
				{
					title: '部门',
					dataIndex: 'departmentName',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'departmentName' },
				},
				{
					title: this.l('ErrorTime'),
					dataIndex: 'attDate',
					width: 140,
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'attDate' },
				},
				{
					title: this.l('SignCardTime'),
					dataIndex: 'checkTime',
					sorter: true,
					width: 160,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'checkTime' },
				},
				{
					title: this.l('SignCardType'),
					dataIndex: 'signCardType',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'signCardType' },
				},
				{
					title: this.l('SignCardReason'),
					dataIndex: 'reasonType',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'reasonType' },
				},
				{
					title: this.l('SignCardDes'),
					dataIndex: 'reason',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'reason' },
				},
			],
			tableData: [],

			_CompanyConfigServiceProxy: undefined, //公司请求
			_DepartmentServiceProxy: undefined, //部门接口
			_fileDownloadService: undefined, //导出报表
			getTableApi: undefined, //表格接口
		};
	},
	created() {
		// 公司
		this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
		//部门
		this._DepartmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
		this._fileDownloadService = fileDownloadService;
		this.getTableApi = new SignCardRecordServiceProxy(this.$apiUrl, this.$api);
	},
	async mounted() {
		this.initCompany();
		if (abpService.abp.userInfo.companyId) {
			this.comPanyId = abpService.abp.userInfo.companyId;
		}
		this.getData();
		this.SignCardTypeList = await Dic.getInstance().getDicAsync('SignCardType');
		this.SignCardReasonList = await Dic.getInstance().getDicAsync('ReasonType');
	},
	methods: {
		searchTree(inputValue, treeNode) {
			return treeNode.data.props.title.includes(inputValue);
		},
		changePeriod(date, dateString) {
			this.startTime = dateString[0];
			this.endTime = dateString[1];
			this.request.skipCount = 0;
			this.pageNumber = 1;
			// this.initialValue = dateString
			this.getData();
		},
		// 公司选择
		handleCompanyChange(value) {
			this.comPanyId = value;
			this.request.skipCount = 0;
			this.pageNumber = 1;
			this.getData();
			this.departmentId = undefined;
			this.getDepTreeData();
		},
		/**
		 * 获取部门
		 */
		getDepTreeData() {
			this.DepTreeData = [];
			this._DepartmentServiceProxy
				.getDepTreeData(this.comPanyId)
				.then((res) => {
					this.DepTree = DepTreeUntils.getInstance().InitSelectData(res);
					this.DepTree.forEach((item) => {
						if (!item.parentId) {
							this.DepTreeData.push(item);
						}
					});
					this.DepTreeData = DepTreeUntils.getInstance().ArrToTree(this.DepTree, this.DepTreeData, false);
				})
				.finally(() => {
					// this.loading = false;
				});
		},
		/**
		 * 选择部门
		 */
		SelectDepartmentTree(e) {
			this.departmentId = e;
			this.request.skipCount = 0;
			this.pageNumber = 1;
			this.getData();
		},
		//初始化公司
		initCompany() {
			this._CompanyConfigServiceProxy
				.getPaged(undefined, '', 100, 0)
				.finally(() => {
					// this.spinning = false;
				})
				.then((res) => {
					this.companyList = res.items;
					this.companyList.forEach((item) => {
						if (item.parentId == 0) {
							var obj = {
								key: item.id,
								value: item.id,
								title: item.fullName,
							};
							this.companyTreeList.push(obj);
						}
					});
					this.initCompanyTree(this.companyTreeList);
					// if (this.companyTreeList.length > 0) {
					//     this.handleCompanyChange(this.companyTreeList[0].key);
					// }
				});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initCompanyTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.companyList.forEach((m) => {
					if (m.parentId == v.value) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id,
							title: m.fullName,
						};
						v.children.push(obj); //放进父级的集合中
						this.initCompanyTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},

		getData() {
			this.loading = true;
			this.getTableApi
				.getPaged(
					undefined,
					this.comPanyId ? this.comPanyId : undefined,
					this.departmentId ? this.departmentId : undefined,
					this.SignCardTypeCode ? this.SignCardTypeCode : undefined,
					this.SignCardReasonCode ? this.SignCardReasonCode : undefined,
					this.startTime ? moment(this.startTime) : undefined,
					this.endTime ? moment(this.endTime) : undefined,
					this.filterText ? this.filterText : undefined,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.then((res) => {
					this.loading = false;
					this.tableData = res.items;
					this.totalItems = res.totalCount;
				});
		},
		//导出
		exportFormClick() {
			this.getTableApi
				.getToExcelFile(
					undefined,
					this.comPanyId ? this.comPanyId : undefined,
					this.departmentId ? this.departmentId : undefined,
					this.SignCardTypeCode ? this.SignCardTypeCode : undefined,
					this.SignCardReasonCode ? this.SignCardReasonCode : undefined,
					this.startTime ? moment(this.startTime) : undefined,
					this.endTime ? moment(this.endTime) : undefined,
					this.filterText ? this.filterText : undefined,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.then((res) => {
					this._fileDownloadService.downloadTempFile(res);
				});
		},
		/**
		 * 排序
		 * @param pagination
		 * @param filters
		 * @param sorter
		 */
		sorterChange(pagination, filters, sorter) {
			if (sorter.order) {
				this.request.sorting = sorter.columnKey + ' ' + sorter.order.replace('end', '');
			} else {
				this.request.sorting = undefined;
			}
			this.search();
		},
	},
};
</script>

<style scoped>
.pagination {
	margin: 10px auto;
	text-align: right;
}
</style>
